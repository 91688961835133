define("common/extra/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CATEGORIES = [{
    id: 2,
    grouping: 'type',
    icon: 'deal-tag',
    name: 'All Deals',
    prefix: 'lfr',
    shortName: 'Deals',
    slug: 'deals'
  }, {
    id: 1,
    grouping: 'type',
    icon: 'ticket',
    name: 'All Coupons',
    shortName: 'Coupons',
    slug: 'coupons'
  }, {
    id: 3,
    grouping: 'type',
    hideInHeader: true,
    name: 'All Offers',
    shortName: 'Offers',
    slug: 'all-offers'
  }, {
    id: 5,
    grouping: 'category',
    icon: 'utensils',
    name: 'Dining',
    shortName: 'Dining',
    slug: 'restaurants-and-food'
  }, {
    grouping: 'type',
    hideInHeader: true,
    icon: 'shipping-fast',
    name: 'Takeout or Delivery',
    shortName: 'Takeout/Delivery',
    slug: 'takeout-or-delivery',
    tags: 'Takeout/Delivery:delivery,Takeout/Delivery:take-out'
  }, {
    id: 7,
    grouping: 'category',
    icon: 'smile',
    name: 'Fun',
    shortName: 'Fun',
    slug: 'entertainment'
  }, {
    id: 9,
    grouping: 'category',
    icon: 'flower-tulip',
    name: 'Salon & Spa',
    shortName: 'Salon & Spa',
    slug: 'health-and-beauty'
  }, {
    id: 6,
    grouping: 'category',
    icon: 'watch-fitness',
    name: 'Fitness & Health',
    shortName: 'Fitness & Health',
    slug: 'sports-and-fitness'
  }, {
    id: 4,
    grouping: 'category',
    icon: 'car-side',
    name: 'Auto',
    shortName: 'Auto',
    slug: 'automotive'
  }, {
    id: 10,
    grouping: 'category',
    icon: 'briefcase',
    name: 'Services',
    shortName: 'Services',
    slug: 'services'
  }, {
    id: 8,
    grouping: 'category',
    icon: 'store',
    name: 'Retail',
    shortName: 'Retail',
    slug: 'retail'
  }, {
    id: 11,
    grouping: 'category',
    icon: 'home-alt',
    name: 'Home',
    shortName: 'Home',
    slug: 'home'
  }, {
    id: 12,
    grouping: 'url',
    icon: 'book',
    name: 'My Local Magazine',
    shortName: 'My Mag',
    url: 'https://books.clippermagazine.com/'
  }, {
    grouping: 'type',
    hideInHeader: true,
    name: 'Price Drop',
    shortName: 'Price Drop',
    slug: 'price-drop'
  }, {
    grouping: 'type',
    hideInHeader: true,
    name: 'Amazing Deals',
    shortName: 'New and Upcoming',
    slug: 'amazing-deals'
  }];
  Object.freeze(CATEGORIES);
  CATEGORIES.forEach(function (category) {
    Object.freeze(category);
  });
  var _default = CATEGORIES;
  _exports.default = _default;
});